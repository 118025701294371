<template>
  <div>
      <nav-bar :wallet='false'></nav-bar>
      <div class="row justify-content-center p-0 mx-0 mb-5">
          <div class="col-lg-10 col-12 px-0">
              <h1 class="mx-md-4 mt-5 rf-title up pl-lg-0 pl-2">
                  WHY FUSE G?
              </h1>
          </div>
          
          <div class="col-12 p-0 m-0">
              <div class="row px-lg-2 m-0 justify-content-start" style='margin-bottom: 20vh;'>
                  <div class='col-12 col-md-12 col-lg-1 clLight'></div>
                  <div class="col-12 col-md-12 col-lg-5 clLight px-0 m-0 pt-3 partners-text position-relative">
                    <p class="texture pl-4 pr-0">
                      <span class='up my-5'>WORLD CLASS REFERRAL SYSTEMS</span>
                      <span>
Here at fuse.gold we have an outstanding single level referral program with FUSEG where anyone
can refer new customers and earn free gold. There is no limit as to how much free gold can be
earned and the percentage of the referral amount can be adjusted to suit market conditions.
Additionally gold X is our single layer referral system where anyone who refers new customers can
earn 10% more gold X during the sign-up phase. Both the referrer and referee earn 10% more gold X.
The gold X referral system also pays the referrer a percentage of future gold X transactions. These
referral gold X amounts are catered for from the inbuilt 10% transaction tax that is applied to each
gold X transaction.<br>
It is also worth noting that the free claim period of gold X automatically transfers any existing
referrals over to the FUSEG referral program. Meaning any successful sign-ups which convert to
FUSEG buys gain the referrer an allocation of FUSEG as well as gold X.

</span>
                       <img src="@/assets/images/pr-mobile.png" class="d-lg-none" style="width: 245px;margin-left: auto;float: right;" alt="" >

                      <span class='up my-5'>FREE PHYSICAL VAULT STORAGE</span>
                      <span>
Due to the nature of the way fuse.gold earns fees for tokenizing physical gold, it allows us to offset
the fees for the storage of the physical gold. Instead of charging our customers for the storage of
their physical gold we only charge for the tokenization; this means we can rival standard storage
facilities of solely physical gold whilst offering a brand new innovative digital solution.
                      </span>
                         <span class='up my-5'>AUTHORISED AND UNREGULATED</span>
                      <span>
fuse.gold is issued by Fuse.Gold Limited, a financial institution regulated by the Gibraltar Financial
Services Commission (GFSC). The GFSC also regulates fuse.gold individual products, ensuring the
highest customer protection and optimal operating standards. The GFSC has approved FUSEG as an
authorised and unregulated fuse.gold product.
                      </span>
                     
                      
                      
                      
                      
                      
                      
                      <span class='up my-5'>EASY TO PURCHASE, TRADE AND REDEEM</span>
                      <span>
You can easily purchase FUSEG tokens with either Bitcoin (BTC), USDC, Fiat currencies (GBP, USD
and EUR) via the fuse.gold website and decentralised exchanges such as uniswap.org or
pancakeswap.io.<br><br>
Easily tradable on both decentralised and centralised exchanges, FUSEG will trade against digital
assets.<br>
Clients can convert their fuse.gold into physically allocated gold, other supported Cryptocurrencies
and FIAT via both decentralised and centralised exchanges. The largest pair being a USD stable
token/FUSEG. Using the fuse.gold platform, customers will be able to reclaim full-sized London
Good Delivery gold bars. FUSEG tokens can be exchanged for physical gold which can be delivered
to you fully insured in shipments insured up to £50,000. (Multiple or bespoke shipments can be
arranged over this amount)
</span>
                      
                      <span>

Programmable
                      </span><span>
Due to the programmable and digital nature of smart contracts, FUSEG is easily converted and
traded. Digital Gold creates important, innovative versatility in the Cryptocurrency space.</span><span>

Low Fees
                      </span>
                       <img src="@/assets/images/arrows-combined.png"  class="pr-sq-imageee" alt="" >
                      
                      <span>
fuse.gold has low fees and therefore is instantly a more attractive investment opportunity compared
to existing gold products. There are no storage fees, the only fees payable stem from converting BTC,
FIAT currencies and other Cryptocurrencies to physical gold (1-2.5%)                      </span>
                      </p>
                  </div>

                  <!-- <div class="col-12 col-md-12 col-lg-2 clLight d-none d-lg-block" >
               <wp-swirel :type='10'></wp-swirel>

                  </div> -->
                  <div class="col-lg-4 d-none d-lg-block">
                      <pr-img-container></pr-img-container>
                  </div>
              </div>
          </div>
          
          <div class="col-12 d-none d-lg-block" style='margin: 18rem 0rem;'>
              <pr-card-section-rev></pr-card-section-rev>
          </div>
          <div class="col-12 d-lg-none clLight mx-0 px-0 Mpartners-text" style="margin-top: 20vw;">
            <h1 class="mt-5 rf-title up pl-4"> We work better <br> together </h1>
            <p class="texture pl-4 py-0">
                <span>
                Partnering with fuse.gold is simple, please reach out to us if you would like further information
about our white-labelling solution for business and enterprise.</span>
            </p>
            <div style="margin-bottom:-55px;">
                <div class="d-inline-block" style="width:40%;">
                       <img src="@/assets/images/wp-1low.jpg" class="mw-100" >
                </div>
                <div class="d-inline-block" style="width:50%;max-height:55px;">
                <span class="gold-line float-left" style="width: 10%;margin-top: 3.5vw;"></span>
                <button class="btn-prim float-right" style="height:7vw;width:90%;font-size:3vw;">
                <a href="mailto: info@fuse.gold?subject=Partner Enquire" class="nolink"> ENQUIRE ABOUT PARTNERING </a>
                </button>
                </div>
            </div>

          </div>
          <div class="d-block d-lg-none mb-5"></div>
          <div class="col-12">
              <partners-info></partners-info>
          </div>
          <!-- <div class="col-12 d-lg-none" style="margin-top:8rem;">
            <h1 class=" rf-title up text-center"> our partners </h1>

          </div> -->

      </div>
    <footer-section></footer-section>

  </div>
</template>

<script>
import WpSwirel from '@/components/swirls/WpSwirel.vue'
import PrCardSectionRev from '@/components/PrCardSectionRev.vue'
import PartnersInfo from '@/components/PartnersInfo.vue'
import PrImgContainer from '@/components/PrImgContainer.vue'

export default {
   components:{
    NavBar:() => import('@/components/NavBar.vue'),FooterSection:() => import('@/components/FooterSection'),
   PartnersInfo,
   PrCardSectionRev,
    WpSwirel,
   PrImgContainer,
   },
    name: "Partner",
}
</script>

<style scoped>
p{
    padding: 20px;
}
</style>