<template>
 <div class="container-fluid p-0 my-5">
      <div class="row">
          <div class="col-xl-4 d-none d-xl-block">
            <!-- <clazy-load src="https://placeholder.pics/svg/300/DEDEDE/555555/Fuse.Gold"> -->
              <img src="@/assets/images/wp-1.webp" class='imgMdRev10' alt="" srcset="">
            <!-- <div class="preloader" slot="placeholder"> -->
            <!-- <img src="https://placeholder.pics/svg/300/DEDEDE/555555/Fuse.Gold" style='max-width: 100%;' alt="" srcset=""> -->
            <!-- </div> -->
        <!-- </clazy-load> -->
          </div>

          <div class="col-xl-8 clLight pw7" style='height: 500px;'>
              <div class="row justify-content-end">
                  <div class="col-11 p-0">
                      <p class='t3 ml-md-5 pl-md-3 up'>We work better together </p>
              <p class='t4 ml-md-5 pl-md-3'>
              Partnering with fuse.gold is simple, please reach out to us if you would like further information about our white-labelling solution for business and enterprise.
              </p>
              <div class='d-none d-xl-block border-bottom-gold' style='max-width: 450px;margin-left:-90px;margin-top: 60px;'>
                <button class="btn-prim  float-right" style='margin-top: -20px;height: 50px;width: 272px;'>
                  <a href="mailto: info@fuse.gold?subject=Partner Enquire" class="nolink"> ENQUIRE ABOUT PARTNERING </a>
                  
              </button>
              </div>
               <button class="d-block d-xl-none btn-prim  m-5" style='margin-top: -20px;height:55px;'>
                  <a href="mailto: info@fuse.gold?subject=Partner Enquire" class="nolink"> ENQUIRE ABOUT PARTNERING </a>
              </button>    
                  
                  </div>
              </div>
              
              
          </div>
        <div class="col-12 px-3 d- d-xl-none">
            <!-- <clazy-load src="https://placeholder.pics/svg/300/DEDEDE/555555/Fuse.Gold"> -->
              <img src="@/assets/images/wp-1.webp" class='d-block mx-auto imgSM'  alt="" srcset="">
            <!-- <div class="preloader" slot="placeholder"> -->
            <!-- <img src="https://placeholder.pics/svg/300/DEDEDE/555555/Fuse.Gold" style='max-width: 100%;' alt="" srcset=""> -->
            <!-- </div> -->
        <!-- </clazy-load> -->
          </div>
      </div>
  </div>
</template>

<script>
export default {
name: "PrCardSectionRev"
}
</script>

<style>

</style>