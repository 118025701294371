<template>
  <div>
      <div class='mt-4 position-relative d-none d-lg-block'
  style='    transform: scale(1.5);
    left: -64px;top: 150px;'
  >
   <!-- <clazy-load src="https://placeholder.pics/svg/300/DEDEDE/555555/Fuse.Gold"> -->

          <img src="@/assets/images/pr1.webp" 
          class='d-block mx-auto'
          style='z-index:1111111;position: absolute;width:100%; left: auto; right: auto;'
          alt="" >

          <img src="@/assets/images/pr2.png" 
          class='d-block mx-auto img-cover3'
          alt="" >

          

        </div>
        <div class='mt-4 position-relative d-block d-lg-none'
  style='
    top: 150px; z-index: 10000;'
  >
  <!-- <clazy-load src="https://placeholder.pics/svg/300/DEDEDE/555555/Fuse.Gold"> -->
          <img src="@/assets/images/pr1.webp" 
          class='d-block mx-auto'
          style='z-index:1111111;position: absolute;width:100%; left: auto; right: auto;'
          alt="" >
          <img src="@/assets/images/pr2.png" 
          class='d-block mx-auto img-cover3'
          alt="" > 
         
         

        </div>
  </div>
</template>

<script>
export default {
    name: "PrImgContainer"
}
</script>

<style>

</style>