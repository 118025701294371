<template>
  <div>
    <div class="row justify-content-center m-0 px-0 py-5" style='margin-top: 150px;'>
        <div class="col-12 mx-0 mt-5 p-0">
            <h1 class="text-center rf-title up">
                  our partners
              </h1>
        </div>
        </div>
        <div class="row ml-2 justify-content-center" v-if='$store.state.dark'>
        <div class="col-lg-2 col-4">
            <img src="@/assets/images/partners/par-dark-1.png" alt="" class="d-block mx-auto" style='max-width: 80%'>
        </div>
        <div class="col-lg-2 col-4">
            <img src="@/assets/images/partners/par-dark-1.1.png" alt="" class="d-block mx-auto mt-4" style='max-width: 52%'>
            <img src="@/assets/images/partners/par-dark-1.2.png" alt="" class="d-block mx-auto" style='margin-top: 16px;max-width: 100%'>
        </div>
        <div class="col-lg-2 col-4">
            <img src="@/assets/images/partners/par-dark-2.png" alt="" class="d-block mx-auto" style='max-width: 80%'>
        </div>
        <div class="col-lg-2 col-4">
            <img src="@/assets/images/partners/par-dark-4.png" alt="" class="d-block mx-auto mt-4" style='max-width: 90%'>
        </div>
        
        <div class="col-lg-2 col-4">
            <img src="@/assets/images/partners/par-dark-5.png" alt="" class="d-block mx-auto mt-4" style='max-width: 90%'>
        </div>
    </div>
        <div class="row justify-content-around ml-2" v-else>
        <div class="col-lg-2 col-4">
            <img src="@/assets/images/partners/par-light-1.png" alt="" class="d-block mx-auto" style='max-width: 80%'>
        </div>
        <div class="col-lg-2 col-4">
            <img src="@/assets/images/partners/par-light-2.2.png" alt="" class="d-block mx-auto mt-4" style='max-width: 52%'>
            <img src="@/assets/images/partners/par-light-2.1.png" alt="" class="d-block mx-auto" style='margin-top: 16px;max-width: 100%'>
        </div>
        <div class="col-lg-2 col-4">
            <img src="@/assets/images/partners/par-light-3.png" alt="" class="d-block mx-auto" style='max-width: 80%'>
        </div>
        <div class="col-lg-2 col-4">
            <img src="@/assets/images/partners/par-light-4.png" alt="" class="d-block mx-auto mt-4" style='max-width: 90%'>
        </div>
        
        <div class="col-lg-2 col-4">
            <img src="@/assets/images/partners/par-light-5.png" alt="" class="d-block mx-auto mt-4" style='max-width: 90%'>
        </div>
    </div>
    
    
  </div>
</template>

<script>
export default {
    
}
</script>

<style>

</style>